html,
body {
  line-height: 1.6;
  font-size: 16pt;
}

.Hyperlink {
  text-decoration: none;
}

.Landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px 0px;
  min-width: 300px;
}

.Me {
  border-radius: 50%;
  width: 300px;
  padding: 20px 0px 20px 0px;
  max-width: 90%;
}

.Greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.Landing > .Greeting > .Name {
  font-size: 32pt;
  text-align: center;
}

.Contact {
  height: 50px;
  margin: 10px;
}

.Intro {
  background-color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.Intro > .About {
  padding: 20px 0px 20px 0px;
  margin: auto;
  width: 90%;
  text-align: center;
}

.Experiences {
  background-color: #fff;
  padding: 20px 0px 20px 0px;
}

.Experience {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 20px 0px 20px 0px;
  width: 90%;
}

@media only screen and (max-width: 1200px) {
  .Experience {
    flex-direction: column;
    align-items: center;
  }
}

.Left {
  min-width: 300px;
  width: 30%;
}

.Right {
  min-width: 300px;
  width: 70%;
  margin-left: 20px;
}

.Logo {
  border: 0px 40px 0px 40px;
  border-radius: 20%;
  padding-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  min-width: 200px;
  max-width: 300px;
}

@media only screen and (max-width: 1200px) {
  .Left {
    margin: auto;
    width: 50%;
  }
  .Right {
    width: 100%;
  }
  .Logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    min-width: 200px;
    max-width: 300px;
  }
}

.Experience > .Right > .Role {
  color: #5e9516;
  font-size: 20pt;
}

.Experience > .Right > .Company {
  font-size: 18pt;
}

.Schools {
  background-color: #eee;
  padding: 40px 0px 40px 0px;
  width: 100%;
}

.School {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  width: 90%;

  padding: 20px 0px 20px 0px;
}

.School > .Left {
  width: 50%;
}

.School > .Right > .Name {
  color: #5e9516;
  font-size: 20pt;
}

.School > .Right > .Degree {
  font-size: 18pt;
}

@media only screen and (max-width: 1200px) {
  .School {
    flex-direction: column;
  }
}

.Skills {
  display: flex;
  flex-direction: column;
  padding: 40px 0px 40px 0px;
}

.Skill {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
}

.Skill > .Type {
  font-size: 20pt;
  color: #5e9516;
  width: 50%;
  min-width: 300px;
  text-align: right;
}

.Skill > .List {
  list-style: none;
  padding-left: 20px;
}

.App {
  min-width: 300px;
}

@media only screen and (max-width: 1200px) {
  .Skill {
    flex-direction: column;
  }
  .Skill > .List {
    padding-left: 0px;
    text-align: center;
  }
  .Skill > .Type {
    text-align: center;
  }
}
